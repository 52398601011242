const zeroAddress = '0x000000000000000000000000000000000000dEaD';
const sepoliaChainID = 11155111;
const arbitrumChainID = 42161;

function getChainID() {
  if (
    !process.env.NEXT_PUBLIC_SKIP_TEST &&
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
  ) {
    return sepoliaChainID;
  }

  return arbitrumChainID;
}

export { getChainID, zeroAddress };
