import { createContext, PropsWithChildren, useState } from 'react';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';
import { CompressionResult, compressPost, useCreatePost, usePostOnChain } from 'services/post';
import getContractAddress from 'services/wagmi/post/address';
import postAbi from 'services/wagmi/post/abi';
import { DatabaseOptions, maxPostTextLength } from 'utils/post';
import { useWriteContract } from 'wagmi';
import { z } from 'zod';

type CreatePostPollState = {
  refetchCount: number;
  pollStartTime: Date | null;
  success: boolean;
  error: string | null;
};

const defaultPollState: CreatePostPollState = {
  refetchCount: 0,
  pollStartTime: null,
  success: false,
  error: null,
};

type CreatePostContextType = {
  submitPostToDB: (text: string, onSuccess?: () => void, onError?: () => void) => void;
  submitPostToChain: (text: string, onSuccess?: () => void, onError?: () => void) => void;
  pollState: CreatePostPollState;
};

const CreatePostContext = createContext<CreatePostContextType>({
  submitPostToDB: () => {
    throw new Error('submitPostToDB not implemented');
  },
  submitPostToChain: () => {
    throw new Error('submitPostToChain not implemented');
  },
  pollState: defaultPollState,
});

export const formSchema = z.object({
  network: z.nativeEnum(DatabaseOptions),
  text: z.string().min(1).max(maxPostTextLength),
});

function CreatePostContextProvider({ children }: PropsWithChildren) {
  const mutation = useCreatePost();

  const [pollState, setPollState] = useState<CreatePostPollState>(defaultPollState);

  const submitPostToDB = async (text: string, onSuccess?: () => void, onError?: () => void) => {
    try {
      const res = await mutation.mutateAsync({ data: text });
      console.log('post res', res);
      if (onSuccess) onSuccess();
    } catch (e) {
      console.error('Error submitting post:', e);
      if (onError) onError();
    }
  };

  const { data: hash, writeContract } = useWriteContract();

  async function submitPostToChain(text: string, onSuccess?: () => void, onError?: () => void) {
    let compressedPost: CompressionResult;
    try {
      const compressedPostResult = await compressPost({ data: text }) as CompressionResult;
      console.log('Compressed post:', compressedPostResult);
      compressedPost = compressedPostResult;

    }
    catch (e) {
      console.error('Error compressing post:', e);
      toast.error('Error compressing post');
      return;
    }

    const toBytes = ethers.toUtf8Bytes(compressedPost.packedData);
    const postTextAsHex = ethers.hexlify(toBytes) as `0x${string}`;

    console.log('Submitting post to chain:', { encoding: compressedPost.encoding, text: compressedPost.packedData, postTextAsHex });
    writeContract(
      {
        address: getContractAddress(),
        abi: postAbi,
        functionName: 'addPostToEvent',
        args: [compressedPost.encoding, postTextAsHex],
      },
      {
        onSuccess: (data) => {
          console.log('transaction hash', data);
          if (onSuccess) onSuccess();
          toast.success('Post created successfully');
          pollPostOnChain();
        },
        onSettled: () => { },
        onError: (e) => {
          console.error(e);
          toast.dismiss();
          toast.error('Error creating post');
          if (onError) onError();
        },
      },
    );
  }

  function pollPostOnChain(onSuccess?: () => void) {
    const maxPollingTime = 5 * 60 * 1000; // 5 minutes in milliseconds
    const pollingInterval = 10 * 1000; // 10 seconds in milliseconds
    let elapsed = 0;
    setPollState({ ...defaultPollState, pollStartTime: new Date() });

    const interval = setInterval(async () => {
      elapsed += pollingInterval;

      setPollState((state) => ({
        ...state,
        refetchCount: state.refetchCount + 1,
      }));
      try {
        const post = await refetchPostOnChain();
        if (post.data) {
          console.log('Post found on-chain:', post.data);
          clearInterval(interval);
          setPollState({ ...defaultPollState, success: true });
          toast.success('Post found on-chain');
          if (onSuccess) onSuccess();
        }
      } catch (err: unknown) {
        console.log('Polling error:', err);
        clearInterval(interval);
        toast.error('Error fetching post from the blockchain.');
        const error = err && typeof err === 'object' && 'message' in err ? (err.message as string) : 'Unknown error';
        setPollState((prevState) => ({
          ...prevState,
          error: error || 'Unknown error',
        }));
      }
      if (elapsed >= maxPollingTime) {
        clearInterval(interval);
        toast.error('Polling timed out. Post might not be available on-chain yet.');
        setPollState((prevState) => ({
          ...prevState,
          error: 'Polling timed out. Post might not be available on-chain yet.',
        }));
      }
    }, pollingInterval);
  }

  const { refetch: refetchPostOnChain } = usePostOnChain(hash);

  const CreatePostContextValue = {
    submitPostToDB,
    submitPostToChain,
    pollState,
  };

  return <CreatePostContext.Provider value={CreatePostContextValue}>{children}</CreatePostContext.Provider>;
}

export { CreatePostContextProvider, CreatePostContext };
