export const maxPostTextLength = 10000;

export enum DatabaseOptions {
  Database = 'Database',
  Mainnet = 'Ethereum Mainnet',
  Sepolia = 'Sepolia',
  Arbitrum = 'Arbitrum ',
}

export function postDateFormatter(date: Date, withTime = true) {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  let hours: number | string = date.getHours();
  if (hours < 10) {
    hours = `0${hours}`;
  }
  let minutes: number | string = date.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  let seconds: number | string = date.getSeconds();
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  const dateString = `${day}/${month}/${year}`;
  if (!withTime) {
    return dateString;
  } else {
    return `${dateString}-${hours}:${minutes}:${seconds}`;
  }
}

export function encodingToString(encoding: number) {
  switch (encoding) {
    case 0:
      return 'plain text';
    case 1:
      return 'smaz';
    case 2:
      return 'gzip';
    default:
      return 'Unknown';
  }
}

export function dataStoreToString(dataStore: number) {
  switch (dataStore) {
    case 1:
      return 'Immutable Database';
    case 2:
      return 'Ethereum Mainnet';
    case 3:
      return '(Testnet) Ethereum Sepolia';
    case 4:
      return 'Arbitrum One';
    default:
      return 'Unknown';
  }
}
